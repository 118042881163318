export default class PrintShare {
	constructor() {
		this.$switchSection = $('.js-share-switch-section');
		this.activeClass = 'js-is-active';
	}

	onSectionSwitch(e) {
		const $elm = $(e.currentTarget);
		const sectionID = $elm.attr('data-section');
		const $actions = $elm.closest('.js-print-share-action');

		$actions
			.find('.js-print-share-action-section')
			.removeClass(this.activeClass)
			.eq(sectionID)
			.addClass(this.activeClass);

		return false;
	}

	setEventBindings() {
		this.$switchSection.on('click', this.onSectionSwitch.bind(this));
	}

	init() {
		this.setEventBindings();
	}
}