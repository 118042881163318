export default class Gallery {
	constructor() {
		this.$gallery = $('.js-justified-gallery');
	    this.delay = 250;
	    this.throttled = false;
	    this.calls = 0;		
		this.page = 1;
		this.loaded = false;
	}

	initGallery() {
		const margins = $(window).outerWidth() < 992 ? 8 : 15;
		const rowHeight = $(window).outerWidth() < 992 ? 105 : 200;
		this.$gallery.justifiedGallery({
		    margins : margins,
		    rowHeight: rowHeight
		});

		const self = this;
		this.$gallery.justifiedGallery().on('jg.complete', function(e){
			if( !self.loaded ){
			    const gallery = lightGallery(self.$gallery.get(0), {
			    	licenseKey: 'A509F455-0EE8474A-9D1375B0-50D5E78D',
			    	download: false
			    });				

			    self.$gallery.data('lightGallery', gallery);				
			}else{
				self.$gallery.data('lightGallery').refresh();
			}

		    self.loaded = true;
		});
	}

	loadLibaries() {
		const css = [
			'/wp-content/themes/classiccatering/source/scripts/libraries/justifiedGallery/justifiedGallery.min.css',
			'/wp-content/themes/classiccatering/source/scripts/libraries/light-gallery/dist/css/lightgallery-bundle.min.css'
		];

		const js = [
			'/wp-content/themes/classiccatering/source/scripts/libraries/justifiedGallery/jquery.justifiedGallery.min.js',
			'/wp-content/themes/classiccatering/source/scripts/libraries/light-gallery/dist/lightgallery.min.js'
		];

		$.each(css, function(key, url){
			var style = document.createElement('link');
			style.href = url
			style.type = "text/css";
			style.rel = "stylesheet";
			style.setAttribute('defer','');
		    document.head.appendChild(style);
		});

	    const self = this;
		$.each(js, function(key, url){
			var script = document.createElement('script');
			script.src = url;
			script.type = "text/javascript";
			script.async = false;
		    document.body.appendChild(script);	

		    if( (key + 1) == js.length ){
				script.addEventListener('load', function() {
					self.initGallery();
				});			
		    }
		});
	}

	loadAdditionalImages(imagesPerPage = 10) {
		if( typeof galleryImages == "undefined" )
			return false;

		let images = JSON.parse(galleryImages);

		if( images.length <= imagesPerPage * this.page ){
			this.$gallery.data('lazyLoadFinished', 1);
		}

		images = images.slice((this.page - 1) * imagesPerPage, imagesPerPage * this.page);

		const self = this;
		let markup = "";
		$.each(images, function(id, image){
			markup += self.buildMarkup(image);
		});

		this.$gallery
			.append(markup)
			.promise()
			.done(function(){
				self.page++;
				self.$gallery.removeData('loading');
			});

		this.$gallery.justifiedGallery('norewind');
	}


	buildMarkup(elm) {
		if( !elm )
			return false;

		let markup = `<a href="${elm.href}" class="gallery__item" data-sub-html="${elm.caption}">`;
		markup += `<img src="${elm.src}"`;
		if( elm.srcset ){
			markup += ` srcset="${elm.srcset}"`;
		}
		markup += '  alt="" height="${elm.height}" width="${elm.width}" />';
		if( elm.caption ){
			markup += `<span class="gallery__item-caption">${elm.caption}</span>`;
		}
		markup += '</a>';

		return markup;
	}

	onScroll() {
		const $window = $(window);
		const scrollTop = $window.scrollTop();
		const windowHeight = $window.outerHeight();
		const elmPos = this.$gallery.offset().top + this.$gallery.outerHeight();
		const imagesPerPage = this.$gallery.attr('data-lazy-amount');

		if( 
			(scrollTop + windowHeight) >= (elmPos - windowHeight)
			&& !this.$gallery.data('loading') 
			&& !this.$gallery.data('lazyLoadFinished')
		){
			this.$gallery.data('loading', 1);

			this.loadAdditionalImages(imagesPerPage);
		}
	}

	setEventBindings() {
		if( this.$gallery.length ){
			this.loadLibaries();

			const self = this;
			window.addEventListener('scroll', function() {
				if (!self.throttled) {

					self.onScroll();

					self.throttled = true;

					setTimeout(function() {
						self.throttled = false;
					}, self.delay);
				}
			});
		}
	}

	init() {
		this.setEventBindings();
	}
}