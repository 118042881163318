export default class YoutubePlayerApi {
	constructor() {
		this.$videoPlayer = $('.js-hero-video-player');
		this.$playBtn = $('.js-hero-video-play');
		this.activeClass = 'js-is-active';
	}

	initVideoPlayers() {
		const self = this;
		this.$videoPlayer.each(function(){
			const $elm = $(this);
			const height = $elm.attr('data-height');
			const width = $elm.attr('data-width');
			const id = $elm.attr('data-video-id');
			const type = $elm.attr('data-type');
			const onLoad = $elm.attr('data-onload') == "true" ? true : false;

			if( onLoad ){			
				let src = false;
				if( type == "youtube" ){
					src = `//www.youtube.com/embed/${id}`;
				} else if( type == "vimeo" ) {
					src = `//player.vimeo.com/video/${id}`;
				} else if( type == "media" ){
					src = id;					
				}

				let player = false
				if( type == "media" ){
					player = self.buildVideoMarkup(src, height, width);
				}else{
					player = self.buildIframeMarkup(type, id, height, width, src);
				}

				$elm.replaceWith(player);
			}
		});
	}

	onClick(e) {
		const $elm = $(e.currentTarget);
		const height = $elm.attr('data-height');
		const width = $elm.attr('data-width');
		const id = $elm.attr('data-video-id');
		const type = $elm.attr('data-type');
		const $videoPlayer = this.$videoPlayer.filter(`[id="${$elm.attr('id')}"]`);
		const $poster = $(`.${$elm.attr('id')} .js-hero-video-poster`);

		$elm.addClass(this.activeClass);

		let src = false;
		if( type == "youtube" ){
			src = `//www.youtube.com/embed/${id}?&autoplay=1`;
		} else if( type == "vimeo" ) {
			src = `//player.vimeo.com/video/${id}?&autoplay=1`;
		} else if( type == "media" ){
			src = id;					
		}

		let player = false
		if( type == "media" ){
			player = this.buildVideoMarkup(src, height, width);
		}else{
			player = this.buildIframeMarkup(type, id, height, width, src);
		}

		$videoPlayer.replaceWith(player);

		if( type == "media" ){
			player.load();
			player.addEventListener('loadeddata', function() {
				player.play();
				$elm.fadeOut(200);
				$poster.fadeOut(600);
			});			
		}else{
			player.onload = () => {
				$elm.fadeOut(200);
				$poster.fadeOut(600);
			}			
		}
	}

	buildIframeMarkup(type, id, height, width, src) {
		var iframe = document.createElement("iframe");
		iframe.height = height;
		iframe.width = width;
		iframe.src = src;
		iframe.frameBorder = 0;
		iframe.setAttribute('allow','accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
		iframe.setAttribute('allowfullscreen', '');
		iframe.setAttribute('class', 'video-hero__video-player');
		return iframe;
	}

	buildVideoMarkup(src, height, width) {
		let video = document.createElement('video');
		video.id = 'loop';
		video.src = src;
		video.width = width;
		video.height = height;
		video.controls = true;
		video.setAttribute('class', 'video-hero__video-player');

		return video;
	}

	setEventBindings() {
		this.initVideoPlayers();

		this.$playBtn.on('click', this.onClick.bind(this));
	}

	init() {
		this.setEventBindings();
	}
}