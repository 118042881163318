const jsBreakpoints = {
    'xs': 0,
    'sm': 576,
    'md': 768,
    'lg': 992,
    'lg-alt': 1200,
    'xl': 1350
}; 

import MobileDetect from '@/components/MobileDetect';
import LazyLoad from '@/components/LazyLoad';
import Form from '@/components/Form';
import Nav from '@/components/Nav';
import TextPreviewSlider from '@/components/TextPreviewSlider';
import YoutubePlayerApi from '@/components/YoutubePlayerApi';
import HeroSlider from '@/components/HeroSlider';
import ReviewSlider from '@/components/ReviewSlider';
import PreviewSlider from '@/components/PreviewSlider';
import PrintShare from '@/components/PrintShare';
import TextRotator from '@/components/TextRotator';
import AlertBanner from '@/components/AlertBanner';
import Popup from '@/components/Popup';
import Gallery from '@/components/Gallery';

$(function() {
    const textRotator = new TextRotator();
    textRotator.init();

    const lazyLoad = new LazyLoad();
    lazyLoad.init();

    const textPreviewSlider = new TextPreviewSlider();
    textPreviewSlider.init();

    const heroSlider = new HeroSlider();
    heroSlider.init();    

    const reviewSlider = new ReviewSlider();
    reviewSlider.init();

    const previewSlider = new PreviewSlider();
    previewSlider.init();

	const mobileDetect = new MobileDetect();
	mobileDetect.init();

    const form = new Form();
    form.init();

    const nav = new Nav(jsBreakpoints);
    nav.init();

    const youtubePlayerApi = new YoutubePlayerApi();
    youtubePlayerApi.init();    

    const printShare = new PrintShare();
    printShare.init();

    const alertBanner = new AlertBanner();
    alertBanner.init();

    const popup = new Popup();
    popup.init();

    const gallery = new Gallery();
    gallery.init();
});