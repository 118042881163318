export default class LazyLoad {
	constructor() {
	    this.delay = 250;
	    this.throttled = false;
	    this.calls = 0;
		this.timeout = false;
	}

	showLazyBg() {
		const scrollTop = $(window).scrollTop();
		const windowHeight = $(window).outerHeight();
		const windowWidth = $(window).outerWidth();
		const self = this;

		$('.lazy-bg').each(function(){
			const $elm = $(this);
			const elmTop = $elm.offset().top;
			const finished = $elm.data('lazy-finished');
			const currentBreakpoint = typeof $elm.data('lazy-current-breakpoint') != "undefined" ? parseInt($elm.data('lazy-current-breakpoint')) : -1;
			let src = false;
			let srcset = $elm.attr('data-bg-srcset');
			let srcset_ordered = $elm.data('srcset_ordered') ? $elm.data('srcset_ordered') : false;

			if( 
				!$elm.data('lazy-finished')
				&& (scrollTop + (windowHeight * 2)) >= elmTop 
			){
				if( srcset ){
					srcset = srcset.split(',');

					if( !srcset_ordered ){	
						srcset_ordered = [];
						for( i = 0; i < srcset.length; i++ ){
							const srcsetObj = srcset[i].trim().split(' ');	
							const data = {
								"src": srcsetObj[0],
								"breakpoint": parseInt(srcsetObj[1].replace('w',''))
							}
							srcset_ordered.push(data);
							$elm.data('srcset_ordered', srcset_ordered);
						}					
					}

					for( i = 0; i < srcset_ordered.length; i++ ){
						// const srcObj = srcset[i].trim().split(' ');
						const breakpoint = srcset_ordered[i]["breakpoint"];

						if( i > currentBreakpoint ){
							if( windowWidth >= breakpoint ){
								if( i == (srcset_ordered.length - 1) ){
									src = srcset_ordered[i]["src"]; 			
									$elm.data('lazy-finished', 1);
								}else{
									$elm.data('lazy-current-breakpoint', i + 1);
									src = srcset_ordered[i + 1]["src"];
								}
							}else if( windowWidth < srcset_ordered[0]["breakpoint"] ){								
								src = srcset[0].trim().split(' ')[0];
							} 
						}
					}

					// for( i = 0; i < srcset.length; i++ ){
					// 	const srcObj = srcset[i].trim().split(' ');
					// 	const breakpoint = srcObj[1].replace('w', '');

					// 	if( i > currentBreakpoint ){
					// 		if( windowWidth >= breakpoint ){
					// 			if( i == (srcset.length - 1) ){
					// 				src = srcObj[0]; 			
					// 				$elm.data('lazy-finished', 1);
					// 			}else{
					// 				$elm.data('lazy-current-breakpoint', i + 1);
					// 				const srcNextObj = srcset[i + 1].trim().split(' ');
					// 				src = srcNextObj[0];
					// 			}
					// 		}else if( windowWidth < srcset[0].split(' ')[1].replace('w', '') ){								
					// 			src = srcset[0].trim().split(' ')[0];
					// 		} 
					// 	}
					// }
				}else{
					$elm.data('lazy-finished', 1);
					src = $elm.attr('data-bg');
				}

				if( !$elm.attr('data-bg') ){
					$elm.addClass('lazy-bg-loaded');
				}

				if( src ){
					$('<img/>')
						.attr('src', src)
						.on('load', function() {
							$(this).remove();
							$elm.css('background-image', 'url(' + src + ')')
							.addClass('lazy-bg-loaded');
						});					
				}
			}
		});
	}

	showLazyImg() {
		const scrollTop = $(window).scrollTop();
		const windowHeight = $(window).outerHeight();
		const self = this;

		$('.lazy-img').each(function(){
			const $img = $(this);
			const imgTop = $img.offset().top;
			const src = $img.attr('data-src');

			if( 
				(scrollTop + (windowHeight * 1.5)) >= imgTop 
			){
				$img
					.removeClass('lazy-img')
					.attr('src', $img.attr('data-src'));
			}
		});
	}	

	lazySrcsetBgSwitch() {
		$('.js-lazy-srcset-switch').each(function(){
			const $elm = $(this);
			const imgTag = $elm.get(0);
			const id = $elm.attr('data-id');
			const $elmToSwitch = $(`[data-lazy-srcset-switch="${id}"]`);
			const oldSrc = $elm.data('currentSrc') ? $elm.data('currentSrc') : false;
			const currentSrc = imgTag.currentSrc || imgTag.src;

			if( oldSrc != currentSrc ){
				$elm.data('currentSrc'. currentSrc);
				$elmToSwitch.css({
					'background-image': `url('${currentSrc}')`
				});
			}
		});
	}

	onScroll() {
		this.showLazyBg();
		this.showLazyImg();
	}

	onResize() {
		this.showLazyBg();
		// this.lazySrcsetBgSwitch();
	}

	setEventBindings() {
		const self = this;

		window.addEventListener('scroll', function() {
			if (!self.throttled) {

				self.onScroll();

				self.throttled = true;

				setTimeout(function() {
					self.throttled = false;
				}, self.delay);
			}
		});

		window.addEventListener('resize', function() {
			clearTimeout(self.timeout);
			self.timeout = setTimeout(self.onResize.bind(self), 250);
		});

		this.showLazyBg();
		this.showLazyImg();
		// this.lazySrcsetBgSwitch();
	}

	init() {
		this.setEventBindings();
	}
}