export default class PreviewSlider {
	constructor() {
		this.$slider = $('.js-preview-slider');
		this.activeClass = 'js-is-active';
	}

	initSliders() {
		const self = this;
		this.$slider.each(function(){
			const $slider = $(this);
			const id = $slider.attr('data-id');

			$slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				arrows: true,
				dots: true,
				centerMode: true,
				centerPadding: 0,
				dotsClass: 'slick-dots  slick-dots--dark  no-style',
				prevArrow: `.js-preview-slider-arrow[data-id="${id}"][data-dir="prev"]`,
				nextArrow: `.js-preview-slider-arrow[data-id="${id}"][data-dir="next"]`,
				speed: 600
			});

			$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				const slideCount = slick.slideCount - 1;
				const nextSlideIsFirst = currentSlide === slideCount;
				const nextSlideIsLast  = nextSlide === slideCount;
				const $activeSlide = $('.slide.slick-active');

				if (nextSlideIsFirst) {
					slick.$slides.eq(currentSlide).next().addClass('slick-transition');
				}

				if (nextSlideIsLast) {
					slick.$slides.eq(0).removeClass('slick-transition');
					slick.$slides.eq(0).next().removeClass('slick-transition');
					slick.$slides.eq(currentSlide).removeClass('slick-transition');
				}
			});

			$slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
				slick.$slider.find('.slick-cloned').removeClass('slick-transition');
			});
		});
	}

	setEventBindings() {
		this.initSliders();
	}

	init() {
		this.setEventBindings();
	}
}