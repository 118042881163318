export default class HeroSlider {
	constructor() {
		this.$slider = $('.js-hero-slider');
		this.$video = $('.js-hero-video-wrap');
		this.activeClass = 'js-is-active';
	}

	initSliders() {
		const self = this;
		this.$slider.each(function(){
			const $slider = $(this);
			const id = $slider.attr('data-id');

			$slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				autoplaySpeed: 4000,
				speed: 1500,
				arrows: false,
				dots: true,
				fade: true,
				dotsClass: 'slick-dots  no-style',
				autoplay: true
			});
		});
	}

	initVideo() {
		const self = this;
		this.$video.each(function(){
			const $elm = $(this);
			const videoSrc = $elm.attr("data-video-src");
			const $wrap = $elm.closest('.js-hero-video');
			const $poster = $wrap.find('.js-hero-video-poster');
			if( videoSrc ){
				const video = self.buildVideoMarkup(videoSrc);
				$elm.append(video);

				video.load();
				video.addEventListener('loadeddata', function() {
					video.play();
					$poster.removeClass(self.activeClass);
				});
			}
		});
	}

	buildVideoMarkup(src) {
		let video = document.createElement('video');
		video.id = 'loop';
		video.muted = true;
		video.loop = true;
		video.width = 3840;
		video.height = 2160;
		video.src = src;
		video.setAttribute('playsinline', true);

		return video;
	}

	setEventBindings() {
		this.initSliders();

		this.initVideo();

	  //   if (window.addEventListener){
			// window.addEventListener("load", this.initVideo.bind(this), false);
	  //   }else if (window.attachEvent){
			// window.attachEvent("onload", this.initVideo.bind(this));
	  //   }else{
			// window.onload = this.initVideo.bind(this);    
	  //   } 
	}

	init() {
		this.setEventBindings();
	}
}