export default class TextRotator {
	constructor() {
		this.$slider = $('.js-text-rotator');
		this.$slide = $('.js-text-rotator-slide');
		this.itr = 0;
		this.maxFontSize = 120;
		this.timeout = false;
		this.activeClass = 'js-is-active';
		this.animationSpeed = 225;
		this.transitionInterval = 350;
		this.rotateSpeed = 3000;
	}

	onResize() {
		const dataAttrs = ['resized', 'dir'];
		this.$slider.removeData(dataAttrs);
		this.setSliderText();
	}

	prepareTextTransition() {
		this.$slide.each(function(){
			const $elm = $(this);
			const text = $elm.find('> div');
			text.html(function(index, html){
			  return html.replace(/\S/g, '<span>$&</span>');
			});
		});
	}

	setSliderText() {
		const self = this;

		self.itr++;

		this.$slider.each(function(){
			const $elm = $(this);
			const $slides = $elm.find('> div > div');
			const sliderWidth = $elm.outerWidth();

			if( $elm.data('reached') )
				return false;

			let newFontSize = 0;
			let largestSlideWidth = 0;
			$slides.each(function(){
				const $slide = $(this);
				const slideWidth = $slide.outerWidth();
				const dir = $elm.data('dir');

				if( slideWidth > largestSlideWidth ){
					largestSlideWidth = slideWidth;
				}
			});			

			if( 
				largestSlideWidth < sliderWidth 
				&& $elm.data('dir') != -1
			){
				newFontSize = parseInt($elm.css('font-size')) + 1;

				if( !$elm.data('dir') ){
					$elm.data('dir', 1);
				}
			}else if( 
				largestSlideWidth > sliderWidth 
				&& $elm.data('dir') != 1
			){
				newFontSize = parseInt($elm.css('font-size')) - 1;

				if( !$elm.data('dir') ){
					$elm.data('dir', -1);
				}
			}else{
				$elm.data('resized', 1);
			}

			if( newFontSize && newFontSize > self.maxFontSize ){
				$elm.data('resized', 1);
			}else if( newFontSize && !$elm.data('resized') ){
				$elm.closest('.text-rotator').css('font-size', newFontSize);
				self.setSliderText();

				return false;								
			}
		});
	}

	initSlider() {
		const self = this;
		const transitionSpeed = 400;
		this.$slider.each(function(){
			const $slider = $(this);
			const $slides = $slider.find('> div')
			const slideCount = $slides.length;

			$slides.eq(0).find('span').css({opacity: 1});

			setInterval(function(){
				const $currentSlide = $slides.filter(`.${self.activeClass}`);
				const currentSlideIndex = $currentSlide.index();
				let $nextSlide = false;

				if( (currentSlideIndex + 1) == slideCount ){
					$nextSlide = $slides.eq(0);
				}else{					
					$nextSlide = $slides.eq(currentSlideIndex + 1);
				}

				$currentSlide.removeClass(self.activeClass);
				$nextSlide.addClass(self.activeClass);

				self.transitionSlide($currentSlide, $nextSlide);
			}, self.rotateSpeed);
		});
	}

	transitionSlide($cur, $next) {
		const curChar = $cur.find('span');
		const curCharCount = curChar.length;
		const nextChar = $next.find('span');
		const nextCharCount = nextChar.length;
		const curSpeed = this.transitionInterval/curCharCount
		const nextSpeed = this.transitionInterval/nextCharCount

		const self = this;
		for( i = curCharCount; i >= 0; i-- ){
			const itr = (curCharCount * curSpeed) - (i * curSpeed);
			self.animateText(itr, curChar.eq(i), 0);
		}

		setTimeout(function(){
			for( i = 0; i < nextCharCount; i++ ){
				const itr = (i * nextSpeed);
				self.animateText(itr, nextChar.eq(i), 1);
			}			
		}, (curCharCount * curSpeed) + 100);
	}

	animateText(i, elm, opacity){
		const self = this;
		setTimeout(function(){
			elm.animate({
				opacity: opacity
			}, self.animationSpeed);
		}, i);
	}

	setEventBindings() {
		this.prepareTextTransition();

		const self = this;
		window.addEventListener('resize', function() {
			clearTimeout(self.timeout);
			self.timeout = setTimeout(self.onResize.bind(self), 250);
		});

		this.setSliderText();

		this.initSlider();
	}

	init() {
		this.setEventBindings();
	}
}