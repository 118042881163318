import { isMobile } from '@/helpers/helpers';

export default class MobileDetect {
	constructor() {
		this.$body = $('body');
	}

	setMobileClass() {
		const mobileCheck = isMobile() ? 'mobile' : 'no-mobile';
		this.$body.addClass(mobileCheck);
	}

	setEventBindings() {
		this.setMobileClass();
	}

	init() {
		this.setEventBindings();
	}
}