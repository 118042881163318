export default class AlertBanner {
	constructor() {
		this.$banner = $('.js-alert-banner');
		this.activeClass = 'js-is-active';
		this.$rotate = false;
	}

	initBanner() {
		if( !this.$banner.is('*') )
			return false;

		const self = this;
		this.$banner.each(function(){
			const $banner = $(this);
			const $items = $banner.find('> div');
			const itemCount = $items.length;
			const duration = $banner.attr('data-speed') ? $banner.attr('data-speed') : 3000;

			self.$rotate = setInterval(function(){
				const $curItem = $items.filter(`.${self.activeClass}`);
				let $nextItem = false;
				if( ($curItem.index() + 1) == itemCount ){
					$nextItem = $items.eq(0);
				}else{					
					$nextItem = $items.eq($curItem.index() + 1);
				}

				$curItem.removeClass(self.activeClass);

				if( $nextItem ){
					$nextItem.addClass(self.activeClass);
				}
			}, duration)
		});
	}

	onMouseOver() {
		clearInterval(this.$rotate);
	}

	onMouseOut() {
		this.initBanner();
	}
	
	setEventBindings() {
		this.initBanner();
		this.$banner.on('mouseenter', this.onMouseOver.bind(this));
		this.$banner.on('mouseout', this.onMouseOut.bind(this));
	}

	init() {
		this.setEventBindings();	
	}
}