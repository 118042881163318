export default class ReviewSlider {
	constructor() {
		this.$slider = $('.js-review-slider');
		this.activeClass = 'js-is-active';
	}

	initSliders() {
		const self = this;
		this.$slider.each(function(){
			const $slider = $(this);
			const id = $slider.attr('data-id');

			$slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				arrows: true,
				dots: true,
				dotsClass: 'slick-dots  slick-dots--dark  no-style',
				prevArrow: `.js-review-slider-arrow[data-id="${id}"][data-dir="prev"]`,
				nextArrow: `.js-review-slider-arrow[data-id="${id}"][data-dir="next"]`
			});
		});
	}

	setEventBindings() {
		this.initSliders();
	}

	init() {
		this.setEventBindings();
	}
}