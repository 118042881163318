export default class TextPreviewSlider {
	constructor() {
		this.$slider = $('.js-text-preview-slider');
		this.$slide = $('.js-text-preview-slider .slick-slide');
		this.activeClass = 'js-is-active';
		this.$nav = $('.js-text-preview-slider-nav');
		this.$navItem = $('.js-text-preview-slider-nav-item');
	}

	setEventBindings() {
		const self = this;
		this.$slider.each(function(){
			const $slider = $(this);
			const id = $slider.attr('data-id');
			const titles = $slider.parent().find('.js-text-slider-titles');

			$slider.on('init', function(event, slick){
				const $slider = slick.$slider;
				const $titles = $(`.js-text-slider-titles[data-id="${id}"] li`);
				const $navPrev = $(`.js-text-preview-slider-nav-item[data-dir="prev"][data-id="${id}"] li`);
				const $navNext = $(`.js-text-preview-slider-nav-item[data-dir="next"][data-id="${id}"] li`);
				const $counter = $(`.js-text-preview-slider-count[data-id="${id}"]`);

				slick.$titles = $titles;
				slick.$navPrev = $navPrev;
				slick.$navNext = $navNext;
				slick.$counter = $counter;
			});

			$slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				arrows: true,
				autoplay: true,
				autoplaySpeed: 3000,
				prevArrow: `.js-text-preview-slider-nav-item[data-dir="prev"][data-id="${id}"]`,
				nextArrow: `.js-text-preview-slider-nav-item[data-dir="next"][data-id="${id}"]`,
			});

			$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
				const $titles = slick.$titles;
				const $navPrev = slick.$navPrev;
				const $navNext = slick.$navNext;
				const $counter = slick.$counter;
 				const slideCountIndex0 = slick.slideCount - 1;
				let direction = currentSlide - nextSlide;

				if( 
					direction == 1 
					|| direction == -slideCountIndex0
				) {
					direction = -1;
				}else{
					direction = 1
				}

				$titles
					.removeClass(self.activeClass)
					.eq(nextSlide)
					.addClass(self.activeClass);

				const prevText = direction == 1 ? currentSlide : nextSlide + direction;
				const nextText = direction == 1 ? nextSlide + direction : currentSlide;

				let next = 0;
				let prev = 0; 

				if( prevText == -1 ){
 					prev = slideCountIndex0;
 					next = 1;
				}else if( nextText > slideCountIndex0 ){
					prev = currentSlide;
					next = 0;
				}else {
					prev = prevText;
					next = nextText;
				}

				if( currentSlide != nextSlide ){
					$navPrev
						.removeClass(self.activeClass)
						.eq(prev)
						.addClass(self.activeClass);

					$navNext
						.removeClass(self.activeClass)
						.eq(next)
						.addClass(self.activeClass);					

					let itr = nextSlide;
					if( nextSlide.toString().length == 1 ){
						itr = `0${nextSlide + 1}`;
					}

					$counter.html(itr);
				}

			});
		});
	}

	setNavColors($elm) {
		const $current = $elm;
		const $siblings = $elm.siblings();
		const curColor = $current.attr('data-color');

		const colorOptions = {
			'#065a30': '#0f6135',
			'#0f6135': '#065a30'
		};

		$current
			.attr('data-color', colorOptions[curColor])
			.css('background', curColor)
		$siblings
			.attr('data-color', curColor)
			.css('background', colorOptions[curColor]);
	}

	onNavItemHover(e) {
		const $elm = $(e.currentTarget);
		this.setNavColors($elm);
	}

	onNavLeave(e) {
		const $elm = this.$navItem.first();
		this.setNavColors($elm);
	}

	init() {
		this.$navItem.on('mouseenter', this.onNavItemHover.bind(this));
		this.$nav.on('mouseleave', this.onNavLeave.bind(this));
		this.setEventBindings();
	}
}