export default class Popup {
	constructor() {	
		this.$popup = $('.js-popup');
		this.$wrap = $('.js-popup > div');	
		this.$popupClose = $('.js-popup-close');
		this.activeClass = 'js-is-active';
	}

	initPopup() {
		const popupViewed = this.getCookie('cc_popup_viewed');
		const refreshTime = this.$popup.attr('data-refresh-time');
		const status = this.$popup.attr('data-status');

		if( status != "active" )
			return false;

		if( 
			refreshTime 
			&& !popupViewed
		){
			this.showPopup();
			this.setCookie('cc_popup_viewed', 1, refreshTime);
		}else if(
			!refreshTime 
		){
			this.showPopup();			
		}

	}

	showPopup() {
		this.$popup.addClass(this.activeClass);
	}

	closePopup() {
		this.$popup.removeClass(this.activeClass);
	}

	onPopupClick(e) {
		if( e.target !== e.currentTarget )
			return;

		this.closePopup();
	}

	onKeyUp(e) {
		if( e.key == "Escape" ){
			this.closePopup();
		}
	}

	setCookie(name,value,days) {
	    var expires = "";
	    if (days) {
	        var date = new Date();
	        date.setTime(date.getTime() + (days*24*60*60*1000));
	        expires = "; expires=" + date.toUTCString();
	    }
	    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}

	getCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	}

	setEventBindings() {
		this.initPopup();
		this.$popup.on('click', this.onPopupClick.bind(this));
		this.$popupClose.on('click', this.closePopup.bind(this));
		$(document).on('keyup', this.onKeyUp.bind(this));
	}

	init() {
		this.setEventBindings();
	}
}