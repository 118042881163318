export default class MobileDetect {
	constructor() {
		this.$document = $(document);
		this.activeClass = 'js-is-active';
		this.hiddenClass = 'js-is-hidden';
		this.$formAjax = $('.js-ninja-ajax');
		this.forms;
		this.scripts;
		this.templates;
		this.styles;
	}

	initForms() {
		const self = this;

		let form_ids = [];

		this.$formAjax.each(function(){
			const $form = $(this);
			const id = parseInt($form.attr('data-form-id'));
			form_ids.push(id);
		});

		if( !form_ids.length )
			return;

		const data = { 
    		async_form: true, 
    		form_ids: form_ids
    	};

        jQuery.post('/', data, function(response){
	        response = JSON.parse(response);

	        window.nfFrontEnd = window.nfFrontEnd || response.nfFrontEnd;
	        window.nfi18n = window.nfi18n || response.nfi18n || {};

	        self.forms = response.form;
	        self.templates = response.templates;
	        self.scripts = response.scripts;
	        self.styles = response.styles;
	    }).then(function(){

	    	$.each(self.forms, function(id, markup){
	    		const $form = self.$formAjax.filter(`[data-form-id="${id}"]`);
		        self.loadForm($form, markup);
	    	});

	        self.loadTemplates(self.templates);
	        self.loadScripts(self.scripts);
	        self.loadStyles(self.styles);
	    });
	}

	loadForm($div, $markup){
		$div.append($markup);
	}

	loadTemplates(templates){
        $('body').append(templates);
	}

    loadScripts(scripts) {
        $.each( scripts, function( nfScript ){
            if( scripts[nfScript].extra.data ){
                var script = document.createElement('script');
                script.id = 'nf-front-end-js-extra';
                script.text = scripts[nfScript].extra.data;
                // document.head.appendChild(script);
				document.body.appendChild(script);
		        // $('body').append(script);
            }
        });

        $.each( scripts, function( nfScript ){
            var script = document.createElement('script');
            script.setAttribute('src',scripts[nfScript].src);
            // var appendChild = document.head.appendChild(script);
			document.body.appendChild(script);

	        // $('body').append(script);
        });
    }	

    loadStyles(styles) {
        $.each(styles, function(nfStyle){
            var link  = document.createElement('link');
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = styles[nfStyle].src;
            link.media = 'all';
            document.head.appendChild(link);
        });
    }

	// self.fetch(id, function(){

	// });

	// fetch(id, callback) {
	// 	const data = { 
 //    		async_form: true, 
 //    		form_id: id 
 //    	}

 //        jQuery.post('/', data, this.fetchHandler.bind(this)).then(callback);
 //    }

    // fetchHandler(response) {
    //     response = JSON.parse( response );

    //     window.nfFrontEnd = window.nfFrontEnd || response.nfFrontEnd;
    //     window.nfi18n = window.nfi18n || response.nfi18n || {};

    //     this.formHTML = response.form;
    //     this.formTemplates = response.templates;
    //     this.formScripts = response.scripts;
    // }	

    load() {
        this.loadFormHTML(this.formHTML, this.targetContainer);
        this.loadTemplates(this.formTemplates);
        this.loadScripts(this.formScripts);
    }    

	onFocus(event){
		const $elm = $(event.currentTarget);
		const $field = $elm.closest('.nf-field-container');

		$field.addClass(this.activeClass);
	}


	onFocusOut(event) {
		const $elm = $(event.currentTarget);
		const $field = $elm.closest('.nf-field-container');
		const value = $elm.val() != "" ? true : false;

		$elm.toggleClass(this.activeClass, value);
		$field.toggleClass(this.activeClass, value);
	}

	onChange(event){
		const $elm = $(event.currentTarget);
		const value = $elm.val() != "" ? true : false;

		$elm
			.closest('.nf-field-element')
			.toggleClass(this.activeClass, value);
	}

	setEventBindings() {
		this.initForms();
		this.$document.on('focus', 'input[type="text"], input[type="email"], input[type="number"], input[type="tel"], textarea', this.onFocus.bind(this));
		this.$document.on('focusout', 'input[type="text"], input[type="email"], input[type="tel"], textarea', this.onFocusOut.bind(this));
		this.$document.on('change', 'select', this.onChange.bind(this));
	}

	init() {
		this.setEventBindings();
	}
}


		// this.$formAjax.each(function(){
		// 	const $form = $(this);
		// 	const id = $form.attr('data-form-id');
		// 	const data = { 
	 //    		async_form: true, 
	 //    		form_id: id 
	 //    	};

	 //    	let form;
	 //    	let templates;
	 //    	let scripts;
	 //    	let styles;

	 //        jQuery.post('/', data, function(response){
		//         response = JSON.parse(response);

		//         window.nfFrontEnd = window.nfFrontEnd || response.nfFrontEnd;
		//         window.nfi18n = window.nfi18n || response.nfi18n || {};

		//         form = response.form;
		//         templates = response.templates;
		//         scripts = response.scripts;
		//         styles = response.styles;

	 //        }).then(function(){
		//         self.loadForm($form, form);
		//         self.loadTemplates(templates);
		//         self.loadScripts(scripts);
		//         self.loadStyles(styles);
	 //        });

		// });
