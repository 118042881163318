export default class Nav {
	constructor(jsBreakpoints) {
		this.$navToggle = $('.js-nav-toggle');
		this.$subNavToggle = $('.js-nav-sub-toggle');
		this.$nav = $('.js-nav');
		this.$adjustedHeight = $('.js-adjusted-height');
		this.$header = $('.js-header');
		this.$headerWrap = $('.js-header-wrap');
		this.$alertBanner = $('.js-alert-banner');
		this.activeClass = 'js-is-active';
		this.timeout = false;
		this.breakpoints = jsBreakpoints;
		this.visible = false;
		this.$headerLogo = $('.js-header-logo');
		this.$headerNav = $('.js-header-nav');
		this.$headerCtas = $('.js-header-ctas');
	}

	onToggle(e) {
		const $elm = $(e.currentTarget);
		this.visible = this.visible == false ? true : false;		

		$('html,body').toggleClass('no-scroll');
		$elm.toggleClass(this.activeClass);
		if( this.visible ){
			this.$nav.scrollTop(0);
		}
		this.$nav.toggleClass(this.activeClass, this.visible);
	}

	onSubToggle(e) {
		const $elm = $(e.currentTarget);
		const $li = $elm.closest('li');
		const $submenu = $li.find('.sub-menu');
		const open = $li.hasClass(this.activeClass) ? false : true;

		if( open ){
			$('.sub-menu').slideUp();

			$li
				.addClass(this.activeClass)
				.removeClass('js-is-inactive')
				.siblings()
				.removeClass(this.activeClass)
				.addClass('js-is-inactive');

			$submenu.slideDown();
		}else{
			$li
				.removeClass(this.activeClass)
				.siblings()
				.removeClass('js-is-inactive')

			$submenu.slideUp();
		}

		return false;
	}

	onResize() {
		this.setElementHeight();
	}

	setElementHeight() {
		// const headerOffset = this.$headerWrap.position().top;
		const headerOffset = 0;
		const headerHeight = this.$headerWrap.outerHeight();
		const windowHeight = $(window).outerHeight();
		const windowWidth = $(window).outerWidth();
		const height = windowHeight - headerHeight;
		const self = this;
		this.$adjustedHeight.each(function(){
			const $elm = $(this);
			const top = $elm.attr('data-top');
			const breakpoint = $elm.attr('data-breakpoint');
			let styles = {};

			if( top == "true" ){
				styles["top"] = headerOffset + headerHeight;
			}

			if( 
				breakpoint
				&& windowWidth <= self.breakpoints[breakpoint]
			){
				styles["min-height"] = '';
			}else{
				styles["min-height"] = Math.round(height);
			}

			$elm.css(styles);
		});
	}

	setEventingBindings() {
		this.$navToggle.on('click', this.onToggle.bind(this));
		this.$subNavToggle.on('click', this.onSubToggle.bind(this));

		const self = this;
		window.addEventListener('resize', function() {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(self.onResize.bind(self), 250);
		});

		this.onResize();
	}	

	init() {
		this.setEventingBindings();
	}
}
